@import "../../scss/abstracts/variables";

.reccomendedForyou {
  &__main-wrapper {
    position: relative;
    display: block;
    padding: 0;
    background-color: #fff;

    @media (max-width: $tablet-portrait) {
      padding: 0;
    }
  }

  &__main-top-content {
    position: relative;
    width: 100%;
    padding: 0 6rem;
    background-image: linear-gradient(to right, #302b26, rgba(48, 43, 38, 0.8));

    @media (max-width: $tablet-portrait) {
      display: block;
    }

    @media (max-width: $phone) {
      padding: 0 2rem;
    }
  }

  &__main-top-content-title {
    width: 100%;
    text-align: left;
    padding: 1rem 0;

    @media (max-width: $tablet-portrait) {
      width: 100%;
    }
  }

  &__card-banner {
    padding: 0 2rem;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 99%);
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;

    p {
      margin: 0;
      font-family: "Roboto Slab";
      font-size: 2rem;
      line-height: 1.65;
      letter-spacing: normal;
      color: #fff;
    }
  }

  &__main-top-content-title > p {
    font-family: "Roboto Slab";
    font-size: 1.8rem;
    line-height: 1.22;
    letter-spacing: normal;
    color: #fff;
    margin: 0;
  }

  &__main-cards-cover {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-around;
    padding: 3.2rem 5rem;

    @media (max-width: $tablet-portrait) {
      display: block;
    }

    @media (min-width: $tablet-portrait) and (max-width: $tablet-landscape) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media (max-width: $phone) {
      padding: 1.5rem;
    }
  }

  &__main-cards {
    width: 32%;
    position: relative;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #ffffff;

    @media (max-width: $tablet-portrait) {
      width: 100%;
      margin: 2rem 0;
    }

    @media (min-width: $tablet-portrait) and (max-width: $tablet-landscape) {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      margin: 7px;
    }
  }

  &__card-top-image {
    position: relative;
    padding: 10rem 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
    transition: box-shadow 0.2s ease-in-out, transform 0.3s ease-in-out;

    cursor: pointer;

    &:hover {
      box-shadow: 0px 1px 1.3rem #666;
    }
  }

  &__main-card-content {
    width: 100%;
    position: relative;
    background-image: url("./image/africa-logo.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    background-color: #594a42;

    @media (min-width: $tablet-portrait) and (max-width: $tablet-landscape) {
      background-position: top right;
    }
    @media (max-width: $tablet-portrait) {
      background-position: top right;
    }
  }
}
