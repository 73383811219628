@import '../../../scss/abstracts/variables';

.registration-close {
    display: block;

    &__words {
        padding-top: 0px;
    }

    &__words-p {
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.76;
        letter-spacing: normal;
        font-family: "HK Grotesk";
        color: #464646;
    }

    &__form {
        width: 100%;
        position: relative;
        background-color: #f5f4f4;
    }

    &__wrapper {
        position: relative;
        padding: 50px;
        padding-bottom: 120px;
    }

    .registration-from__text {
        padding: 25px 60px;
        margin-left: auto;

        a {
            color: #ffff;
        }
    }
}

.registration-bottom {
    display: flex;
}

.termsconditions-close {
    display: block;
    &__words {
        padding: 2rem 3rem;
        border: solid 1px #e0e0e0;
        background-color: white;
        height: 500px;
        overflow-y: scroll;
        
        p, li {
            font-size: 14px;
            font-family: "HK Grotesk", sans-serif;
            margin-bottom: 1rem;
        }

        ol {
            padding-left: 10px;
        }

        ul {
            list-style-type: disc;
            padding-left: 20px;
            margin-bottom: 10px;
        }
    }

    &__form {
        width: 100%;
        position: relative;
        top: 26px;
        background-color: #f5f4f4;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 0px 0px, rgba(255, 255, 255, 0.1) 0px 3px 0px 5px;
    }

    &__wrapper {
        position: relative;
        padding: 0 5rem;
        padding-bottom: 0;

        @media screen and (max-width: $phone) {
            padding: 0 2rem;
        }
    }

    &__header {
        font-size: 2rem;
    }
}

.termsconditions-from__text {
    width: 50%;
    position: relative;
    font-family: "HK Grotesk";
    text-align: right;
    padding: 30px 0;
}

.termsconditions-from__img {
    width: 50%;
}

.termsconditions-bottom {
    display: flex;
    width: 100%;
    padding: 0 50px;
}

a.Close {
    border-radius: 3px;
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #849e43 !important;
    color: #ffff;
    padding: 10px 27px;
    font-family: HK Grotesk;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.76;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
}

.error {
    font-weight: bold;
    color: #ff6008
  }
  
.text-highlight {
    font-weight: bold;
    font-size: 2rem;
    color: #ff6008
}