@import "../../scss/abstracts/variables";
@import "../../scss/abstracts/mixins";

.container {
    background-color: #d6d1c9;
    border-top: solid 0.5rem #d8be8d;
    @include content-padding;
}

.main-card-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 3rem;

    @media screen and (max-width: $tablet-portrait) {
        grid-template-columns: 1fr;
    }
}

.main-wrapper {
    position: relative;
    display: block;
    @include content-size;
}

.main-content {
    display: grid;
    gap: 28px;

    @media screen and (max-width: $tablet-portrait) {
        gap: 15px;
    }
}

.main-top-content {
    position: relative;
    display: flex;
    // width: 100%;

    @media (max-width: 768px) {
        display: block;
    }

    &__title {
        width: 70%;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__title > h1 {
        @include section-title;
    }

    &__title > p {
        font-family: "HK Grotesk";
        font-size: 17px;
        line-height: 1.76;
        color: #464646;
        margin-bottom: 2rem;
        
        @media (max-width: 768px) {
            font-size: 1.5rem;
        }
    }

    &__clickables {
        width: 30%;
        margin: auto;
        position: relative;
        text-align: end;
        padding-top: 7px;

        @media (max-width: 768px) {
            width: 100%;
            text-align: left;
        }
    }

    &__tag {
        border-radius: 3px;
        box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
        background-color: #594a42;
        color: #ffff !important;
        font-family: "HK Grotesk";
        font-size: 1.7rem;
        font-weight: bold;
        line-height: 1.76;
        cursor: pointer;
        padding: 10px;

        @media (max-width: 768px) {
            font-size: 1.5rem;
        }
    }
}
