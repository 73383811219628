@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/variables';

.handpicked-accomodations {
    background-color: #d6d1c9;
    border-top: solid .5rem #d8be8d;
    @include content-padding;
}

.main-wrapper {
    position: relative;
    @include content-size;
}

.main-content {
    // padding: 2.8rem 0 3rem;
    display: grid;
    gap: 28px;

    @media screen and (max-width: $tablet-portrait) {
        gap: 15px;
    }
}

.main-top-content {
    position: relative;
    display: flex;

    @media (max-width: $tablet-landscape) {
        display: block;
        padding-bottom: 1rem;
    }
}

.main-top-content-title {
    width: 70%;

    @media (max-width: $tablet-landscape) {
        width: 100%;
    }
}

.main-top-content-title > h1 {
    @include section-title;
}

.main-top-content-title > p {
    font-family: "HK Grotesk";
    font-size: 1.7rem;
    line-height: 1.76;
    color: #464646;

    @media (max-width: $tablet-landscape) {
        font-size: 1.5rem;
    }
}

.main-top-content-clickables {
    width: 30%;
    margin: auto;
    position: relative;
    text-align: end;

    @media (max-width: $tablet-landscape) {
        width: 100%;
        text-align: left;
    }
}

.main-top-content-tag {
    border-radius: 3px;
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #594a42;
    color: #ffff !important;
    font-family: "HK Grotesk";
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1.76;
    cursor: pointer;
    padding: 1rem;
    -webkit-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;

    @media (max-width: $tablet-landscape) {
        display: inline-block;
        font-size: 1.5rem;
    }
}

.main-top-content-tag-africa {
    color: #b39758 !important;
    font-family: "HK Grotesk";
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    padding-top: 1rem;

    @media (max-width: $tablet-landscape) {
        font-size: 1.5rem;
    }
}

.clickables {
    padding-top: .7rem;
}

.main-cards-cover {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    width: 100%;
    position: relative;
    justify-content: space-between;

    @media (max-width: $tablet-portrait) {
        grid-template-columns: 1fr;
    }
}
