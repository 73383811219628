.search {
    &__container {
        height: 100px;
        display: flex;
    }
    &__col1 {
        background-color: red;
        height: 100px;
        width: 20%;
    }
    &__col2 {
        background-color: #fff;
        height: 100px;
        width: 70%;
        margin-top: 50px;
    }
    &__col3 {
        background-color: red;
        height: 100px;
        width: 10%;
    }
    &__form {
        background-color: #efefef;
    }
    &__destination,
    &__guests {
        margin-right: 50px;
    }
    &__btn {
        width: 145px;
        height: 42px;
        border-radius: 3px;
        background-color: #b39758;
        border: 3px solid #b39758;
        border-radius: 3px;
        color: #fff;
    }
}