@import "../scss/abstracts/mixins";
@import "../scss/abstracts/variables";

button {
    border: none;

    &:focus {
        outline: none;
    }
}

.container {
    // border-top: solid 5px #d8be8d;
    @include content-padding;
    @include content-size;

    @media only screen and (max-width: 767px) {
        display: block;
    }
}

.map {
    position: relative;
    height: 450px;
    width: 450px;
    overflow: hidden;
    float: right;
    border: solid 1px #997c53;
    margin-left: 5rem;
    margin-bottom: 3rem;

    @media screen and (min-width: $desktopXL) {
        height: 600px;
        width: 600px;
    }

    @media screen and (max-width: $tablet-portrait) {
        display: none;
    }
}

.text {
    font-family: "HK Grotesk";
    font-size: 1.1rem;
    font-weight: normal;
    line-height: 1.55;
    letter-spacing: 4px;
    color: #997c53;
    text-transform: uppercase;
    margin-bottom: 14px;

    @media screen and (min-width: $desktopXL) {
        font-size: 1.6rem;
    }
}

.title {
    @include section-title;
}

.subtitle {
    font-family: "HK Grotesk";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #997c53;
    margin-bottom: 30px;
}

.content {
    position: relative;
    font-family: "HK Grotesk";
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.76;
    letter-spacing: normal;
    color: #464646;
    margin-bottom: 30px;
    overflow-y: hidden;
    max-height: 2000px;
    transition: max-height 1s linear;
    
    @media only screen and (max-width: 767px) {
        width: 100%;
    }
    
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
    }
    
    &-closed {
        max-height: 450px;

        @media screen and (min-width: $desktopXL) {
            max-height: 600px;
        }
    }
}

.btn {
    width: 130px;
    height: 42px;
    border-radius: 3px;
    background-color: #b39758;
    padding: 8px 22px;
    color: #fff;
    font-size: 15px;

    &:hover {
        color: #fff;
    }

    @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 30px;
    }
}