@import "../../scss/abstracts/variables";

.PageHero {
  width: 100%;
  height: 100vh;
  margin-top: 0;
  position: relative;
  display: grid;
  overflow: hidden;

  .feature-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.8);
  }

  .carousel {
    width: 100%;
    height: 100%;
    margin: 0;
    grid-row: 1/1;
    grid-column: 1/1;
  }

  .content {
    position: absolute;
    bottom: 20%;
    left: 10%;
    width: 80vw;
  }
}

.header {
  font-family: "Roboto Slab";
  font-size: 6.4rem;
  font-weight: bold;
  line-height: 1.13;
  letter-spacing: -1px;
  color: rgba(255, 255, 255, 0.8);
  z-index: 1;

  @media screen and (min-width: $desktopXL) {
    font-size: 8rem;
  }
  
  @media screen and (max-width: $tablet-portrait) {
    font-size: 5rem;
  }
  
  @media screen and (max-width: $tablet-portrait) and (orientation: landscape) {
    font-size: 3rem;
    line-height: 1;
  }

  @media screen and (max-width: $phone) {
    font-size: 4rem;
  }
}

.description {
  font-family: "HK Grotesk";
  font-size: 2.5rem;
  line-height: 1.6;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  
  @media screen and (min-width: $desktopXL) {
    font-size: 3.5rem;
  }

  @media screen and (max-width: $tablet-portrait) {
    font-size: 3rem;
    line-height: 1.3;
  }

  @media screen and (max-width: $tablet-portrait) and (orientation: landscape) {
    font-size: 2.5rem;
    line-height: 1.2;
  }

  @media screen and (max-width: $phone) {
    font-size: 2.5rem;
    line-height: 1.2;
  }
}
