@import '../../../scss/abstracts/variables';

.react-tabs {
  position: relative;
  width: 100%;

  &__tab-list {
    padding: 0;
    float: right;
    position: relative;
    top: -46px;
    left: -8.8rem;

    @media screen and (max-width: $tablet-landscape) {
      left: -1rem;
  }

    @media screen and (max-width: $phone) {
      top: -36px;
  }
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 1.5rem 3.2rem;
    cursor: pointer;
    background: #efefef;
    border-radius: 5px 5px 0 0;
    margin-left: 6px;

    @media screen and (max-width: $phone) {
      padding: 1rem 2.5rem;
  }


    &:focus {

      outline: none;

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }

  }

  &__tab--selected {
    border-radius: 5px 5px 0 0;
    background-color: #594a42;

  }
  &__tab--selected{
    color: #fff
  }
  &__tab--selected p {
    color: #fff
  }

  &__tab--disabled {
    color: #949494;
    cursor: default;

  }

  &__tab-panel {
    display: none;
  }

  &__tab-panel--selected {
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
  }
}

.title-owner {
  font-family: 'Roboto Slab';
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #949494;
}

.back-drop {
  background-color: rgba(126, 116, 105, 0.8);
  height: 100%;
  position: fixed;
  transition: all 1.3s;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 999999;
}

.back-drops {
  background-color: rgba(126, 116, 105, 0.4);
  height: 100%;
  position: fixed;
  transition: all 1.3s;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 9999;
}

.back-drops2 {
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  position: absolute;
  transition: all 1.3s;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 9999;
}