.info-window {
  img {
    width: 200px;
    object-fit: contain;
    border-radius: 10px;
  }

  a {
    color: #464646;
  }
}
