.registration-form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 2rem;
  gap: 2rem;
  width: 100%;
  position: relative;
  background-color: #f5f4f4;
  box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.25), 0 3px 0 5px rgba(255, 255, 255, 0.1);

  &__textarea {
    width: 100%;
  }

  &__form {
    // position: absolute;
    width: 100%;
    top: -2px;
  }

  &__first {
    // position: relative;
  }

  &__second {
    width: 50%;
    position: relative;
  }

  &__wb-field {
    padding-bottom: 0;
    font-size: 16px;
    font-family: 'HK Grotesk';
    color: #464646;
    height: 72px;
    display: grid;
    grid-template-rows: min-content 1fr;
    margin-top: 1rem;
  }

  &__button {
    position: relative;
    float: right;
    width: 38%;
    right: 18px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__terms>a {
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.76;
    font-family: 'HK Grotesk';
    letter-spacing: normal;
    color: #ff6008 !important;
  }

  .req {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-family: 'HK Grotesk';
    line-height: 1.76;
    letter-spacing: normal;
    color: #ff6008;
  }

  .errors {
    color: #ff6008;
    font-size: 1.2rem;
    font-family: 'HK Grotesk';
    text-align: right;
    margin-bottom: 0; 
  }
}

//input label

input {
  width: 100%;
  padding: 4px;
  font-family: 'HK Grotesk' !important;
}

input[type='file'] {
  border: solid 1px #e0e0e0;
  padding-top: 4px;
  background-color: white;
  align-items: baseline;
  color: inherit;
  font-family: 'HK Grotesk';
  text-align: start !important;
}

.registration-form .container {
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  padding-right: 0;
  font-family: 'HK Grotesk';
  line-height: 1.76;
}

input[type="checkbox"] {
  position: absolute;
  z-index: 99;
  width: 30px;
  height: 30px;
  left: 0;
  opacity: 0;
}

input {
  padding-left: 10px;
  padding-right: 4px;
  font-size: 16px;
}

input:focus {
  outline-color: #d8be8d;
  color: #594a42;

}

.terms-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  height: 72px;
}

.checkmark {
  position: relative;
  height: 25px;
  width: 25px;
  background-color: #ffff;
  margin-top: 2px;
  border: solid 1px #dddddd;
}

.create_account {
  background-color: #b39758;
  color: white;
  border: none;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.76;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 3px;
  font-family: 'HK Grotesk';
  cursor: pointer;
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
}

.create_account input:focus {
  outline-color: #d8be8d;
  color: #ffff;

}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark:after {
  content: "";
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 15px;
  border: solid #ec7e12;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg) translate(90%, -10%);
}

.load_image {
  border-radius: 10px;
  width: 50%;
}

.loads {
  float: left;
}

.file-upload {
  display: block;
  text-align: center;
  font-size: 12px;
}

.file-upload .file-select {
  display: block;
  border: solid 1px #a9a9a9;
  color: #34495e;
  cursor: pointer;
  text-align: left;
  background: #FFFFFF;
  overflow: hidden;
  width: 100%;
  padding: 8px;
  padding-left: 9px;
  position: relative;
  border-radius: 0;
}

.file-upload .file-select .file-select-button {
  padding: 10px;
  display: inline-block;
  line-height: 0;
  cursor: pointer;
  border: solid 1px rgba(0, 0, 0, 0.08);
  background-image: linear-gradient(to bottom, #faf9f9, #dedede);
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #7b7b7b;
}

.file-upload .file-select .file-select-name {
  line-height: 25px;
  display: inline-block;
  padding: 0 3px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #8b8b8b;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}

.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all .2s ease-in-out;

}

.file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  color: #FFFFFF;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload .file-select input[type=file] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}

input.react-file-reader-input {
  position: absolute !important;
  width: 100% !important;
  cursor: pointer;
}

span.error.invalid-response {
  position: relative;
  margin-left: 20px;
  color: #ff1b1b;
}

.registration-modal.modal-body {
  padding: 0;
}

.wrap-box {
  background-color: #f5f4f4;
}