@import '../../scss/abstracts/variables';

/*
PropertyInner styles
*/

.property-type {
  font-family: 'Roboto Slab';
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1.5px;
  color: #7fa73c;
  text-transform: uppercase;
  border-bottom: 1px dotted #7fa73c;
  margin-bottom: 0;
  margin-left: 1rem;
}

.badge {
  font-family: 'HK Grotesk';
  font-size: 13px;
  text-align: center;
  color: #594a42;
  border-radius: 2px;
  border: 1px solid rgba(216, 190, 141, 0.25);
  background-image: linear-gradient(
    180deg,
    rgba(238, 224, 193, 0.1),
    rgba(216, 190, 141, 0.5)
  );
  padding: 5px;
}

.rooms {
  font-family: 'HK Grotesk';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: normal;
  color: #7fa73c;
  margin: 15px 0 0;
  padding: 0 0 15px;
}

/*
SafariInner styles
*/

.company {
  font-family: 'Roboto Slab';
  font-size: 13px;
  font-weight: bold;
  line-height: 1.38;
  letter-spacing: 1.5px;
  color: #b39758;
  text-transform: uppercase;
  margin: 10px 0;
}

/*
EntryCard styles
*/

.card {
  display: grid;
  grid-template-columns: 3fr 5fr 2fr;
  background: #fff;
  // margin: 30px 40px 0.725rem 0;
  border-radius: 4px;
  border: solid 1px rgba(119, 119, 119, 0.5);
  box-shadow: 7px 8px 22px -13px rgba(0, 0, 0, 0.9);
  transition: box-shadow 0.2s ease-in-out, transform 0.3s ease-in-out;

  &__top {
    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 13px #666;
  }

  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }

  @media screen and (max-width: $tablet-portrait) {
    display: block;
    margin-top: 0;
    margin-right: 0;
  }
}

.card-safari {
  @media screen and (min-width: 992px) {
    display: block;
    flex-basis: 33.333333%;
    max-width: 33%;
    margin-bottom: 3rem;
  }
}

.card-small {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  border: solid 1px rgba(119, 119, 119, 0.5);
  box-shadow: 7px 8px 22px -13px rgba(0, 0, 0, 0.9);
  transition: box-shadow 0.2s ease-in-out, transform 0.3s ease-in-out;

  &__top {
    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 13px #666;
  }
}

.col-1 {
  position: relative;
  padding: 1rem;
  display: grid;
  gap: 1rem;
  border-right: 2px solid #f5f4f4;
}

.col-1-small {
  position: relative;
  padding: 0;
}

.col-2 {
  min-height: 200px;
  height: 100%;
  padding: 0.5rem;
  display: grid;
  grid-template-rows: repeat(2, min-content) 1fr min-content;

  @media screen and (max-width: $tablet-portrait) {
    padding: 1rem;
  }
}

.col-2-small {
  min-height: 300px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  // display: grid;
  // grid-template-rows: repeat(2,min-content) 1fr min-content;
}

.col-3 {
  position: relative;

  @media screen and (max-width: $tablet-portrait) {
    display: none;
  }
}

.col-3-small {
  display: none;
}

.image-container {
  position: relative;

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
  }
}

.book {
  @media screen and (max-width: $tablet-portrait) {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }
}

.book-safari {
  @media screen and (min-width: 992px) {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }
}

.book-small {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
}

.tooltip {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

/*
Shared styles
*/

.top {
  display: grid;
  grid-template-columns: 1fr max-content;

  &__logo {
    height: 6rem;
    width: 6rem;
    align-self: center;

    img {
      height: 100%;
      object-fit: contain;
    }
  }
}

.title {
  font-family: 'HK Grotesk';
  font-size: 2.4rem;
  font-weight: 700;
  color: #7fa73c;
}

.location {
  display: grid;
  grid-template-columns: repeat(3, max-content) 1fr;
  align-items: center;
  gap: 0.5rem;
  background-color: #f5f4f4;
  height: 40px;
  margin-bottom: 1rem;
  padding-left: 10px;

  p {
    font-family: 'HK Grotesk';
    font-size: 14px;
    color: #997c53;
    margin: unset;
  }
}

.list-icon {
  width: 10px;
  height: 13px;
  object-fit: contain;
  margin: auto 20px;
}

.share {
  text-align: center;
  align-items: center;
  cursor: pointer;
  float: right;
  height: 100%;
  position: relative;
}

.description {
  flex: 1;
  font-family: 'HK Grotesk';
  font-size: 14px;
  line-height: 1.63;
  color: #464646;
  padding-right: 10px;
  // word-break: break-all;
}

.bottom {
}
