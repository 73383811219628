.toolbar {
  &__banner-content {
    position: relative;
    width: 100%;
    margin: auto;
    text-align: center;

    >img {
      margin: 0 auto;
      padding: 8px;
    }
  }

  &__banner-topic {
    color: #ffff;
    font-family: "HK Grotesk";
    font-size: 24px;
    font-weight: 600;
    letter-spacing: normal;
  }

  &__banner-order-now {
    font-family: "HK Grotesk";
    font-size: 17px;
    font-weight: bold;
    line-height: 1.53;
    letter-spacing: normal;
    text-align: center;
    color: #ffff;
  }

  &__container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    padding-right: 2rem;


    @media only screen and (max-width: 767px) {
      width: 100%;
      position: fixed;
      padding: 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 100%;
    }
  }

  &__right {
    width: 100%;
    display: flex;

    @media only screen and (min-width: 1280px) and (max-width: 1280px) {
      width: 70%;
    }

    @media (max-width: 767px) {
      height: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 70%;
      margin-right: -55px;

    }
  }

  &__container-left {

    @media only screen and (max-width: 767px) {
      width: 100%;
      position: relative;
    }
  }

  &__container-right {
    width: 0;
    padding-top: 10px;
    // height: 100%;

    @media only screen and (max-width: 767px) {
      width: 20%;
      padding-top: 0;
    }
  }

  &__wb-fav-icon {
    width: 6%;
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 0;

    @media only screen and (max-width: 767px) {
      margin-left: 10px;
    }
  }
  &__top-brand {
    width: 100%;
    background-color: #000;

    @media (min-width: 767px) {
      display: none;
    }
  }
}

.bg-menu-mobile {
  &__mobile-section-wrapper {
    height: 100vh;
    position: absolute;
    width: 100%;
    left: 0;
  }

  &__mobile-section-menu-items {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    background-color: rgba(48, 43, 38, 0.85);
  }

}

.menu_mobile{
  @media (min-width: 767px) {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  #bg-menu-mobile {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: -100%;
    will-change: transform;
  }

  ul.mobile-menu-ul {
    width: 80%;
    position: relative;
    top: 0;
    left: 100%;
    list-style: none;
    z-index: 3;
    padding-top: 30px;

  }

  ul.mobile-menu-u-2 {
    width: 17%;
    position: relative;
    top: 0;
    list-style: none;
    background-color: #ffffff;
    z-index: 3;
    font-family: "Roboto Slab";
    font-size: 18px;
    letter-spacing: 1px;
    margin-bottom: 0;
    left: 100%;
    padding-top: 150px;
  }

  ul.mobile-menu-ul li.mobile-menu-item {
    margin-bottom: 10px;
    left: 100%;
    cursor: pointer;
    will-change: transform;
    font-family: "Roboto Slab";
    font-size: 18px;
    letter-spacing: 1px;
    color: #f9f9f9;

    >a{
      color: #ffff !important;
    }
  }

  ul.mobile-menu-ul li.mobile-menu-item:hover {
    color: #111;
    transition: all .1s ease-in-out;
  }

  ul.mobile-menu-u-2 li.mobile-menu-item {
    margin-bottom: 20px;
    left: 100%;
    cursor: pointer;
    will-change: transform;
    text-align: center;
  }
  ul.mobile-menu-u-2 li.mobile-menu-item:last-child {
    left: 36%;
    bottom: 15%;
    position: absolute;

  }
  ul.mobile-menu-u-2 li.mobile-menu-item:nth-child(3) {
    left: 42%;
    bottom: 20%;
    position: absolute;

  }

  ul.mobile-menu-u-2 li.mobile-menu-item:hover {
    color: #111;
    transition: all .1s ease-in-out;
  }


  #hamburger {
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 9999;
    transform: rotate(180deg);
    position: relative;
  }

  #hamburger:hover span:nth-child(1) {
    top: 60%;
    transition: .20s ease-in-out;
  }

  #hamburger:hover span:nth-child(3) {
    top: 30%;
    transition: .16s ease-in-out;
  }

  #hamburger span {
    z-index: 3;
    display: block;
    position: absolute;
    height: 2px;
    width: 80%;
    background: #d8be8d;
    border-radius: 5px;
    opacity: 1;
    left: 4px;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }


  #hamburger span:nth-child(1) {
    top: 60%;
  }

  #hamburger span:nth-child(2) {
    top: 45%;
  }

  #hamburger span:nth-child(3) {
    top: 30%;
  }

  #hamburger.open span:nth-child(1) {
    top: 50%;
    transform: rotate(135deg);
    background-color: #fff;
  }

  #hamburger.open span:nth-child(2) {
    display: none;
  }

  #hamburger.open span:nth-child(3) {
    top: 50%;
    transform: rotate(-135deg);
    background-color: #fff;
  }
}

.open {
  background: #dca959;
}