@import '../../scss/abstracts/variables';

.Copyright {
    background-color: #594a42;
    display: flex;
    padding: 2rem;

    @media screen and (max-width: $tablet-landscape) {
        display: block;
    }

    .left {
        font-family: "HK Grotesk";
        font-size: 1.8rem;
        font-weight: normal;
        letter-spacing: 1.2px;
        color: #f2edeb;
        margin-right: auto;

        .bookings {
            color: #d8be8d;
        }
    }
    .right {
        display: flex;
        align-items: center;
        
        @media screen and (max-width: $tablet-landscape) {
            margin-top: 2rem;
        }
    }
    .get-social {
        font-family: "HK Grotesk";
        font-size: 1.5rem;
        font-weight: normal;
        line-height: 1;
        letter-spacing: 2px;
        color: #f2edeb;
        text-transform: uppercase;
        margin-right: 1.5rem;
    }

    .icon {
        width: 17px;
        height: 17px;
        object-fit: contain;
        margin-right: 1.5rem;
    }
}
