$accent: #00bcd4;
$primary: #212121;
$secondary: #727272;

$scoreRed: #f44336;
$scoreYellow: #ffeb3b;
$scoreGreen: #4caf50;

.password {
  display: block;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  color: $secondary;

  &__input {
    display: block;
    text-transform: none;
    width: 100%;
    font-weight: 400;
    color: $primary;
  }

  &__show {
    cursor: pointer;
    position: absolute;
    bottom: 77px;
    right: 55px;
    color: white;
    padding: 4px 8px;
    img {
      width: 16.6px;
      height: 13px;
    }
  }
  &__shows {
    cursor: pointer;
    position: absolute;
    color: white;
    top: 0;
    right: 2rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 

    img {
      width: 16.6px;
      height: 13px;
    }
  }
}
