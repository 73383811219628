@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

$timing: 100ms;
$iconColor: #594a42;
$accent: #b39758;
$grape: #7b00e0;
$orchid: #ae31d9;

@mixin transformScale($size: 1) {
  transform: scale($size);
  -ms-transform: scale($size);
  -webkit-transform: scale($size);
}

.card-shareable {
  text-align: center;
  align-items: center;
  padding: 7px;
  cursor: pointer;
  float: right;
  height: 100%;
  position: relative;

  > img {
      margin: 0 auto;
      cursor: pointer;
  }
}

.social {
  &__container {
    position: absolute;
    top: -50%;
    z-index: 9;
  }

  &__icons {
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      position: relative;
      font-size: 1.5em;
    }

    &-top {
      bottom: 10px;
    }

    &-bottom {
      top: 5px;
    }

    a {
      display: block;

      i {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all $timing ease-out;
        border: none;
        margin: unset;
      }

      &:before {
        content: " ";
        width: 33px;
        height: 33px;
        border-radius: 100%;
        display: block;
        background: linear-gradient(45deg, $iconColor, $accent);
        transition: all $timing ease-out;
      }

      &:hover i {
        transform: scale(1.25) translate(-40%, -40%);
      }
    }
  }
}