@import "../scss/abstracts/mixins";
@import "../scss/abstracts/variables";

.Statistics {
  display: grid;
  grid-template-columns: 0.8fr 1fr 3fr;
  border-top: solid 5px #d8be8d;
  border-bottom: solid 5px #594a42;
  position: relative;
  margin-bottom: 6rem;

  @media only screen and (max-width: $tablet-portrait) {
    grid-template-columns: 1fr 3fr;
  }
  @media only screen and (max-width: $phone) {
    display: block;
  }
}
.animal {
  width: 100%;
  height: 100%;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: $tablet-portrait) {
    display: none;
  }
}
.banner-container {
  clip-path: polygon(0% 0%, 100% 0%, 99% 100%, 50% 95%, 0% 100%);
  background: url("../../images/leather-strap2.png") no-repeat;
  background-size: cover;
  width: 100%;
  transform: scaleY(1.1) translateY(8px);

  @media only screen and (max-width: $phone) {
    display: none;
  }
}

.banner-content-area {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transform: scaleY(0.85);

  @media only screen and (min-width: $desktopXL) {
    padding: 3rem;
  }
}
.banner-slogan-section {
  font-size: 2rem;
}
.content-area-container {
  // padding: 3rem;
  padding: 3rem 0;
}

.banner-text {
  font-family: "HK Grotesk";
  font-size: 1.7rem;
  line-height: 1.65;
  letter-spacing: normal;
  color: #f2edeb;

  @media only screen and (min-width: $desktopXL) {
    font-size: 2.5rem;
    line-height: 1.45;
  }
}
.banner-slogan {
  font-family: "Roboto Slab";
  font-size: 4.2rem;
  font-weight: 300;
  line-height: 1.14;
  letter-spacing: normal;
  color: #fff;

  &--bold {
    font-weight: bold;
    color: #dca959;
  }
}

.logo-section {
  display: flex;
  justify-content: center;
  
  img {
    object-fit: contain;
    height: 180px;

    @media screen and (max-width: $tablet-portrait) {
       height: 120px;
      }
      
      @media screen and (max-width: $phone) {
      height: 90px;
    }
  }
}

.counter-section {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;

  @media screen and (min-width: $tablet-landscape) {
    grid-template-columns: repeat(2, 270px);
    gap: 2rem;

    &__field {
      padding-top: 100%;
      position: relative;

      &--inner {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media only screen and (min-width: $desktopXL) {
    grid-template-columns: repeat(2, 32rem);
  }
}

.content-area {
  width: 100%;
  height: 100%;
  // display: grid;
  // grid-template-columns: 1fr;
  // gap: 3rem;
}

.counter-section-top,
.counter-section-bottom {
  display: flex;
  @media only screen and (max-width: $tablet-portrait) {
    display: block;
  }
}
.counter-section-top {
  margin-bottom: 4rem;
  @media only screen and (max-width: $tablet-portrait) {
    margin-bottom: 0;
  }
}
.counter-number {
  font-family: "Roboto Slab";
  font-size: 4.2rem;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #594a42;
  margin-bottom: 1rem;
  @media only screen and (max-width: $tablet-portrait) {
    font-size: 3rem;
  }
  @media only screen and (min-width: $desktopXL) {
    font-size: 5rem;
  }
}
.counter-title {
  font-family: "Roboto Slab";
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #b39758;
  margin-bottom: 1rem;
  @media only screen and (max-width: $tablet-portrait) {
    font-size: 1.8rem;
  }

  @media only screen and (min-width: $desktopXL) {
    font-size: 3rem;
  }
}
.counter-text {
  font-family: "HK Grotesk";
  font-size: 1.7rem;
  font-stretch: normal;
  line-height: 1.76;
  letter-spacing: normal;
  text-align: center;
  color: #464646;

  @media only screen and (max-width: $tablet-portrait) {
    // width: 80%;
    margin-bottom: 2rem;
    margin: 0 auto;
    font-size: 1.5rem;
  }

  @media only screen and (min-width: $desktopXL) {
    font-size: 2.2rem;
  }
}
