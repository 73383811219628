@import "../../scss/abstracts/variables";

.tick {

    .tooltip {
        display: inline-block;
        position: relative;
        border-bottom: 0;
        text-align: left;
        opacity: 1;
        width: 100%;
        z-index: unset;

        img {
            width: 90px;
            z-index: 0;
            margin-left: 10px;
            cursor: pointer;

            @media screen and (max-width: $tablet-portrait) {
                width: 60px;
            }

            @media screen and (max-width: $phone) {
                width: 40px;
            }
        }

        img.small {
            width: 45px;
            height: 45px;
        }

        h3 {
            margin: 12px 0;
        }
    }
}
