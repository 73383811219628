// @import "../../fonts/stylesheet.css";
@import "../scss/defaults";
@import "../scss/toolbar";
@import "../scss/header";
@import "../scss/search";
@import "../scss/inserted";
@import "../scss/abstracts/mixins";
@import "../scss/abstracts/variables";

html {
  font-family: "Roboto Slab";
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;

  @media only screen and (max-width: $phone) {
    font-size: 50%;
  }
}

body {
  font-family: "Roboto Slab";
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: hsla(0, 0%, 0%, 0.8);
  font-family: "Roboto Slab", serif;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  font-feature-settings: "kern", "liga", "clig", "calt";
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: none !important;
}

p {
  padding-left: 0 !important;
}

// strong {
//   font-weight: 300;
// }

a:active,
a:hover {
  outline-width: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

img {
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

h1 {
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: "Roboto Slab";
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 2.25rem;
  line-height: 1.1;
}

h2 {
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: "Roboto Slab";
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.62671rem;
  line-height: 1.1;
}

h3 {
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: "Roboto Slab";
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.38316rem;
  line-height: 1.1;
}

ul {
  margin-left: 1.45rem;
  margin-bottom: 1.45rem;
}

table {
  width: 100%;
}

li {
  margin-bottom: calc(1.45rem / 2);
}

ul li {
  padding-left: 0;
}

li *:last-child {
  margin-bottom: 0;
}

@media (max-width: $tablet-landscape) {
  ::-webkit-scrollbar {
    display: none;
  }
}
