
//This component overrides default styles for html that has been inserted to the DOM via React-Quill

.inserted-html {
  // strong {
  //   font-weight: 300;
  // }

  a {
    font-weight: bold;
    color: #997c53 !important;
  }
}