.modal-main {

  &__banner {
    display: flex;
    width: 100%;
    // position: absolute;
    background: #594a42;
  }

  &__banner-first {
    // width: 50%;
    // padding-left: 45px;
    position: relative;
    margin: auto;
  }

  &__banner-second {
    width: 50%;

  }

  &__banner-first p {
    float: left;
    font-family: 'HK Grotesk';
    padding-left: 4px;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    padding-right: 3px;
    margin: auto;
  }

  &__banner-second-img img {
    position: relative;
    width: 5rem;
    height: 100%;
    object-fit: cover;
    float: right;
    margin-bottom: 0;
  }

  &__banner-first-img img {
    float: left;
    position: relative;
    width: 6%;
    margin: 4px 3px;

  }

  &__banner-first-a {
    float: left;
    text-transform: uppercase;
    color: #d8be8d;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    font-family: 'HK Grotesk';
  }

}