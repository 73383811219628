@import './variables';

@mixin content-padding {
  padding: 1rem 3rem;

  @media (max-width: $tablet-portrait) {
    padding: 3rem 9rem;
  }

  @media screen and (max-width: $tablet-portrait) and (orientation: landscape) {
    padding: 3rem 4rem;
  }

  @media (max-width: $phone) {
    padding: 3rem 3rem;
  }
}

@mixin flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin content-size {
  max-width: 130rem;
  margin: 1rem auto;
}

@mixin divider-title {
  font-family: 'Roboto Slab';
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.17;
  color: #594a42;

  @media only screen and (max-width: $tablet-portrait) {
    font-size: 2.2rem;
  }
}

@mixin section-title {
  font-family: 'Roboto Slab';
  font-size: 4.2rem;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: normal;
  color: #594a42;
  margin-bottom: 2rem;

  @media only screen and (max-width: $tablet-portrait) {
    font-size: 2.8rem;
  }
}

@mixin page-base-styles {
  padding: 4rem 20rem 0 15rem;
  position: relative;

  @media only screen and (max-width: $desktop) {
    padding: 3rem 16rem 0 5rem;
  }

  @media only screen and (max-width: $phone) {
    padding: 0 3rem;
  }
}

@mixin tilted-side-text {
  position: absolute;
  right: 0;
  top: 4rem;
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  text-transform: capitalize;
  font-weight: 900;
  color: #efefef;
  margin-left: 0;
  white-space: nowrap;

  p {
    font-size: 16rem;
    margin: 0;
    line-height: 1;
  }

  @media screen and (max-width: $phone) {
    display: none;
  }
}

@mixin line-below {
  width: 100%;
  height: 4px;
  background-color: rgba(153, 124, 83, 0.1);
  margin-bottom: 25px;

  @media only screen and (max-width: $tablet-portrait) {
    margin-bottom: 15px;
  }
  @media only screen and (min-width: $tablet-portrait) and (max-width: $desktop) {
    margin-bottom: 30px;
  }
}
