@import '../../../scss/abstracts/variables';

.close_btn {
    float: right;
    position: relative;
    padding-top: 17px;
    padding-right: 12px;
    padding-left: 20px;
}

span.close_title {
    position: absolute;
    padding-top: 28px;
    margin-left: 28px;
}

.modal-wrapper {
    background: white;
    border: 1px solid #d0cccc;
    margin: 0px auto 0;
    transition: all .8s;
    width: 55%;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999999999999999999;
    @media only screen and (min-width: 1280px) and (max-width: 1600px) {
        margin: -100px auto 0;

    }

}

.modal-content {
    transform: translateY(100%);

    @media only screen and (max-height: 600px) {
        transform: translateY(10%);
    }
}

.modal-header {
    background: #ffff;
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.25), 0 0 0 5px rgba(255, 255, 255, 0.1);

    @media screen and (max-width: $tablet-landscape) {
        padding-bottom: 6rem;
    }
}

.modal-header h3 {
    color: #594a42;
    float: left;
    margin: 0;
    padding-left: 22px;
    font-family: 'Roboto Slab';
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    font-size: 24px;

}

.modal-main {
    height: auto;
    position: relative;
    flex: unset;
    padding: 0;
}

.modal-footer {
    background: #263238;
    height: 35px;
    padding: 15px;
}

.close-modal-btn {
    color: white;
    cursor: pointer;
    float: right;
    font-size: 30px;
    margin-top: -10px;

    >img{
        margin: 0;
        width: 30px;
    }
}


.btn-cancel,
.btn-continue {
    background: coral;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    padding: 10px;
}

.btn-cancel {
    background-color: #b71c1c;
    float: left;
}

.btn-continue {
    background-color: #1b5e20;
    float: right;
}


.open-modal-btn {
    background: transparent;
    border: none;
    font-family: 'Roboto Slab';
    font-size: 15px;
    font-weight: normal;
    line-height: 1.33;
    color: #fff;
    display: flex;
    margin-right: 0;
    cursor: pointer;

    &:hover{
        color: #c8a963;

    }
    &:focus{
        outline: none;
    }
}

img.img-loader {
    top: 30%;
    position: relative;
    left: 44%;
    right: 50%;
    width: 12%;
}