.header {
    &__container {
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        margin: 0 auto;
        z-index: 999;
        display: flex;
        position: relative;

        @media only screen and (max-width: 767px) {
            width: 100%;
            display: none;
        }
    }

    &__logo-section {
        width: 100%;
        padding: 3px 30px;

        a {
            display: flex;
        }
        
        img {
            height: 90px;
        }

        @media only screen and (max-width: 767px) {
            width: 100%;
            img {
                height: 70px;
                object-fit: contain;
            }
        }
    }

    &__title {
        font-family: "Roboto Slab";
        font-size: 28px;
        font-weight: bold;
        line-height: 1.07;
        color: #594a42;
        text-transform: lowercase;
    }

    &__logo-container {
        display: inline-block;
    }

    &__logo-top {
        display: flex;
    }

    &__logo-border {
        border-bottom: solid 1px #d8be8d;
        width: 150px;
    }

    &__dot-com {
        font-family: "Roboto Slab";
        font-size: 10px;
        line-height: 1;
        letter-spacing: 1px;
        color: #594a42;
        text-transform: uppercase;
        margin-top: 17px;
        margin-left: 5px;

        @media only screen and (min-width: 1280px) and (max-width: 1280px) {
        }

        @media only screen and (max-width: 767px) {
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
        }
    }

    &__tagline {
        font-family: "HK Grotesk";
        font-size: 12px;
        letter-spacing: 0.3px;
        color: #997c53;
        text-transform: lowercase;
        margin-left: 30px;
    }

    &__menu-section {
        width: 57%;
        padding-top: 30px;

        @media only screen and (max-width: 767px) {
            display: block;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            display: block;
        }
    }

    &__search-section {
        width: 100%;
        padding-top: 30px;
        margin-right: 30px;

        @media only screen and (max-width: 767px) {
            display: none;
        }
    }

    &__search-mobile-section {
        width: 50%;
        padding-top: 0;
        position: absolute;
        right: 0;
        top: 23px;

        @media only screen and (min-width: 767px) {
            display: none;
        }
    }

    &__login-btn {
        display: flex;
        width: 80px;
        height: 35px;
        border-radius: 3px;
        border: solid 0.5px rgba(153, 124, 83, 0.3);
        padding: 5px 10px;
        margin: auto;

        @media only screen and (max-width: 767px) {
            display: none;
        }
    }

    &__menu-items {
        list-style: none;
        display: flex;
        font-family: "Roboto Slab";
        font-size: 15px;
        font-weight: normal;
        line-height: 1.33;
        letter-spacing: normal;
        margin: 0;
        margin-right: auto;
        li {
            white-space: nowrap;
        }
    }

    &__menu-items.two {
        display: flex;
        margin-right: 0;
    }

    &__menu-items > li > a {
        color: #fff;

        &:hover {
            color: #d8be8d;
            border-bottom: 1px solid;
            padding-bottom: 10px;
        }
    }

    &__menu-items > li {
        margin: auto;
        padding: 10px 1.3vw;
        height: 40px;
    }

    &__search-icon {
        width: 18.7px;
        height: 18.7px;
        float: right;
        margin-right: 20px;
    }

    &__login-icon {
        width: 14px;
        height: 14px;
        object-fit: contain;
        margin-top: 5px;
        margin-bottom: 0;
    }

    &__login-text {
        font-family: "HK Grotesk";
        font-size: 17px;
        font-weight: normal;
        line-height: 1.47;
        letter-spacing: normal;
        color: #464646;
        margin-left: 4px;
        margin-top: 0;
        text-transform: lowercase;
        opacity: 0.6;

        > a {
            color: #464646;

            &:hover {
                color: #464646;
            }
        }
    }
}
