@import "../scss/abstracts/variables";

.header {
  position: absolute;
  width: 100vw;
  max-width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;

  @media screen and (max-width: $desktop) {
    display: block;
  }
}
