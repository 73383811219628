

@import '../scss/abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem 1rem;

  @media screen and (max-width: $tablet-portrait) {
    padding: 1rem 1rem 0;
  }
}

.top {
  font-family: "Italianno";
  font-size: 5rem;
  // color: #464646;
  color: #fff;
  line-height: .7;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #d8be8d;

  @media screen and (max-width: $tablet-portrait) {
    font-size: 4rem;
  }
}

.bottom {
  text-transform: uppercase;
  line-height: 1;
  font-size: 2rem;
  color: #e5e5e5;
  margin-top: 5px;

  @media screen and (max-width: $tablet-portrait) {
    font-size: 1.5rem;
  }
}

.middle {
  color: #d8be8d;
  font-size: 2rem;
  margin-bottom: 0;

  @media screen and (max-width: $tablet-portrait) {
    font-size: 1.7rem;
  }
}

.top-light {
  font-family: "Italianno", cursive;
  font-size: 4rem;
  color: #ffffff;
  line-height: .7;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #ffffff;
}

.bottom-light {
  text-transform: uppercase;
  line-height: 1;
  font-size: 1.5rem;
  color: #ffffff;
}

.middle-light {
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: .5rem;
}
