@import "../../scss/abstracts/mixins";
@import "../../scss/abstracts/variables";

.InfoSection {
    position: relative;
    padding: 0 5rem 3rem 8rem;

    @media screen and (max-width: $desktop) {
        padding: 0 5rem 3rem 3rem;
    }

    @media screen and (max-width: $phone) {
        padding: 0 3rem 1rem
    }

    .grid {
        display: grid;
        grid-template-columns: 45rem repeat(3, max-content);
        gap: 3rem;
        position: relative;

        @media screen and (max-width: $desktop) {
            grid-template-columns: 1fr repeat(2, max-content) 1fr;
        }

        @media screen and (max-width: $tablet-portrait) {
            grid-template-columns: 1fr;
        }
    }

    .logo-section {
        margin-bottom: 2rem;
    }

    .logo {
        margin-right: 1rem;
        object-fit: contain;

        @media screen and (max-width: $tablet-portrait) {
            height: 70px;
        }

        @media screen and (max-width: $phone) {
            height: 60px;
        }

        @media screen and (min-width: $desktop) {
            height: 90px;
        }
    }

    .content,
    .content a {
        font-family: "HK Grotesk";
        font-size: 1.7rem;
        line-height: 1.65;
        letter-spacing: normal;
        color: #464646;
    }

    .box-title {
        font-family: "Roboto Slab";
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.22;
        letter-spacing: normal;
        color: #b39758;
        margin-bottom: 2rem;
    }

    .menu-items {
        list-style: none;
        margin: 0;
        padding-left: 0;

        li a {
            font-family: "HK Grotesk";
            font-size: 1.7rem;
            line-height: 1.65;
            letter-spacing: normal;
            color: #464646;

            &:hover {
                color: #d8be8d;
            }
        }

        ul li a {
            font-family: "HK Grotesk";
            font-size: 1.7rem;
            line-height: 1.65;
            letter-spacing: normal;
            color: #464646;

            &:hover {
                color: #d8be8d;
            }
        }
    }

    .contact-btn {
        padding: 2rem 5rem;
        text-align: center;
        font-family: "HK Grotesk";
        font-size: 1.7rem;
        font-weight: bold;
        line-height: 1.53;
        letter-spacing: normal;
        border-radius: 3px;
        border: solid 1px #b39758;
        background-color: transparent;
        padding: 0.8rem 2.2rem;
        color: #b39758;
        background: #fff;
        margin-top: 2rem;
    }
}
